<script>
export default {
  name: "inbox",

  metaInfo: {
    title: "Mensajes",
  },
};
</script>

<template>
  <div id="inbox">
    <div class="content_width page_gap">
      <div class="page__header">
        <h1 class="page__title">Buzón</h1>
      </div>
      <div class="content">
        <h4>No tienes mensajes sin leer</h4>
        <p>Cuando contactes, los mensajes de su anfitrión aparecerán aquí.</p>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.page__header {
  margin-bottom: 1.5rem;
}
</style>
