import { render, staticRenderFns } from "./account-inbox.vue?vue&type=template&id=605d2cf8&scoped=true&"
import script from "./account-inbox.vue?vue&type=script&lang=js&"
export * from "./account-inbox.vue?vue&type=script&lang=js&"
import style0 from "./account-inbox.vue?vue&type=style&index=0&id=605d2cf8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "605d2cf8",
  null
  
)

export default component.exports